var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("ยอดขายรวม รายวัน รายเดือน รายปี")])]),_c('v-col',{attrs:{"cols":"12","md":"7","sm":"7"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menufrom",attrs:{"close-on-content-click":false,"return-value":_vm.datefrom,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.datefrom=$event},"update:return-value":function($event){_vm.datefrom=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"วันที่เริ่ม","readonly":"","dense":"","solo":""},model:{value:(_vm.datefrom),callback:function ($$v) {_vm.datefrom=$$v},expression:"datefrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menufrom),callback:function ($$v) {_vm.menufrom=$$v},expression:"menufrom"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","min":_vm.firstDate},model:{value:(_vm.datefrom),callback:function ($$v) {_vm.datefrom=$$v},expression:"datefrom"}},[_c('v-btn',{attrs:{"text":"","color":"#234baa"},on:{"click":function($event){_vm.menufrom = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"#234baa"},on:{"click":function($event){return _vm.$refs.menufrom.save(_vm.datefrom)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menuto",attrs:{"close-on-content-click":false,"return-value":_vm.dateto,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateto=$event},"update:return-value":function($event){_vm.dateto=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"วันที่สิ้นสุด","readonly":"","dense":"","solo":""},model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuto),callback:function ($$v) {_vm.menuto=$$v},expression:"menuto"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}},[_c('v-btn',{attrs:{"text":"","color":"#234baa"},on:{"click":function($event){_vm.menuto = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"#234baa"},on:{"click":function($event){return _vm.$refs.menuto.save(_vm.dateto)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"5","sm":"5","xs":"12"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-btn',{staticClass:"mt-2 mb-4 mr-4",attrs:{"width":"80px"},on:{"click":function($event){return _vm.clear()}}},[_vm._v("เคลียร์")]),_c('v-btn',{staticClass:"mt-2 mb-4",attrs:{"width":"80px","color":"#833133","dark":""},on:{"click":function($event){return _vm.getForm()}}},[_vm._v("ค้นหา")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"mr-4",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"ค้นหา"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer')],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersOrder,"items":_vm.items,"search":_vm.search,"items-per-page":10},scopedSlots:_vm._u([{key:"item.orderdate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.orderdate).toLocaleDateString())+" ")]}},{key:"item.orderStatus",fn:function(ref){
var item = ref.item;
return [(item.orderStatus == 'WAITING_PAYMENT')?_c('v-chip',{attrs:{"color":"blue","dark":""}},[_vm._v("รอชำระเงิน")]):_vm._e(),(item.orderStatus == 'WAITING')?_c('v-chip',{attrs:{"color":"yellow"}},[_vm._v("WAITING")]):_vm._e(),(item.orderStatus == 'SUCCESS')?_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v("ชำระเงินสำเร็จ")]):_vm._e(),(item.orderStatus == 'CANCEL')?_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v("ยกเลิก")]):_vm._e(),(item.orderStatus == 'DERIVERED')?_c('v-chip',[_vm._v("ส่งแล้ว")]):_vm._e(),(item.orderStatus == 'PAID')?_c('v-chip',[_vm._v("PAID")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{staticClass:"mx-2",on:{"click":function($event){return _vm.UpdateOrder(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.DeleteOrder(item)}}},[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }